.mobile-projects-container {
  padding: 80px 0;
  background: linear-gradient(to bottom, var(--color-bg), var(--color-bg-variant));
  color: var(--color-white);
}

.container {
  width: 85%;
  max-width: 1200px;
  margin: 0 auto;
}

.page-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.subtitle {
  text-align: center;
  color: var(--color-light);
  max-width: 700px;
  margin: 0 auto 3rem;
  line-height: 1.6;
}

/* Tabs */
.tabs-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 10px;
}

.tabs {
  display: flex;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 32px;
  padding: 6px;
  overflow: hidden;
  white-space: nowrap;
  min-width: min-content;
}

.tab-button {
  background: transparent;
  border: none;
  color: var(--color-white);
  padding: 8px 12px;
  font-size: 0.85rem;
  border-radius: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  white-space: nowrap;
}

.tab-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.tab-button.active {
  background-color: var(--color-primary);
  color: var(--color-bg);
}

/* Responsive adjustments for tabs */
@media (max-width: 767px) {
  .tabs {
    padding: 4px;
  }

  .tab-button {
    padding: 6px 10px;
    font-size: 0.8rem;
  }
}

/* Extra adjustments for very small screens */
@media (max-width: 359px) {
  .tab-button {
    padding: 5px 8px;
    font-size: 0.75rem;
  }
}

/* Projects Grid */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

/* Project Card */
.project-card {
  background-color: var(--color-bg-variant);
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.project-image-container {
  position: relative;
  aspect-ratio: 9/16;
  max-height: 300px;
  overflow: hidden;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.featured-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--color-primary);
  color: var(--color-bg);
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 500;
}

.project-content {
  padding: 1.5rem;
  flex-grow: 1;
}

.project-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.project-short-description {
  color: var(--color-light);
  margin-bottom: 1rem;
  font-size: 0.95rem;
  line-height: 1.5;
}

.project-technologies {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 1rem;
}

.project-tech-badge {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--color-light);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
}

.project-tech-badge.more {
  background-color: transparent;
  border: 1px dashed rgba(255, 255, 255, 0.2);
}

.project-footer {
  padding: 0 1.5rem 1.5rem;
}

.view-details-button {
  width: 100%;
  background-color: var(--color-primary);
  color: var(--color-bg);
  border: none;
  padding: 10px;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.view-details-button:hover {
  background-color: var(--color-primary-variant);
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  padding: 1rem;
}

.modal-content {
  background-color: var(--color-bg);
  border-radius: 16px;
  width: 100%;
  max-width: 900px;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-title {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0 0 15px 0;
  color: var(--text-primary);
  line-height: 1.2;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.2s;
}

.close-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.modal-body {
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 768px) {
  .modal-body {
    grid-template-columns: 1fr 1fr;
  }
}

/* Modal Phone */
.modal-phone-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 320px;
  margin-right: 30px;
}

.modal-phone {
  position: relative;
  width: 250px;
  height: 520px;
  background-color: #000;
  border-radius: 36px;
  padding: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.phone-notch-modal {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 128px;
  height: 20px;
  background-color: #000;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.phone-screen-modal {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 32px;
  overflow: hidden;
  position: relative;
}

.media-toggle-modal {
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.toggle-buttons-modal {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 24px;
  padding: 4px;
  backdrop-filter: blur(8px);
  display: flex;
}

.toggle-button-modal {
  border: none;
  background: transparent;
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-button-modal.active {
  background-color: var(--color-primary);
  color: var(--color-bg);
}

.screen-content-modal {
  position: relative;
  width: 100%;
  height: 100%;
}

.app-screenshot-modal,
.app-video-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Modal Navigation buttons */
.image-navigation-modal {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}

.nav-button-modal {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.nav-button-modal:hover {
  background-color: var(--color-primary);
  color: white;
}

/* Modal Pagination dots */
.pagination-dots-modal {
  position: absolute;
  bottom: -32px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.pagination-dot-modal {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
}

.pagination-dot-modal.active {
  background-color: var(--color-primary);
}

/* Modal Project Details */
.project-details-modal {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 10px;
}

.project-description-modal {
  color: var(--color-light);
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.technologies-modal {
  margin-bottom: 1.5rem;
}

.technologies-title-modal {
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.75rem;
}

.technology-badges-modal {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.badge-modal {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 0.9rem;
}

.download-links-modal {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .download-links-modal {
    grid-template-columns: 1fr 1fr;
  }
}

.app-store-link-modal,
.play-store-link-modal {
  display: block;
  background-color: var(--color-primary);
  color: var(--color-bg);
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  text-decoration: none;
}

.app-store-link-modal:hover,
.play-store-link-modal:hover {
  background-color: var(--color-white);
  color: var(--color-bg);
  transform: translateY(-3px);
}

.additional-links-modal {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: auto;
}

.github-link-modal,
.website-link-modal {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: var(--color-white);
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  text-decoration: none;
}

.github-link-modal:hover,
.website-link-modal:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: var(--color-primary);
}

/* Add these styles for disabled links */
.app-store-link-modal.disabled,
.play-store-link-modal.disabled,
.github-link-modal.disabled,
.website-link-modal.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--color-light);
}

.app-store-link-modal.disabled,
.play-store-link-modal.disabled {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--color-light);
  border: none;
}

.github-link-modal.disabled,
.website-link-modal.disabled {
  border-color: rgba(255, 255, 255, 0.1);
}

/* Add these styles to your existing mobile-projects.css file */

.screenshot-slider-modal {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.app-screenshot-modal {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-out;
}

.sliding-next-modal .app-screenshot-modal {
  animation: slideLeftModal 0.3s forwards;
}

.sliding-prev-modal .app-screenshot-modal {
  animation: slideRightModal 0.3s forwards;
}

@keyframes slideLeftModal {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes slideRightModal {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

/* Make pagination dots clickable */
.pagination-dot-modal {
  cursor: pointer;
  transition: all 0.2s ease;
}

.pagination-dot-modal:hover {
  transform: scale(1.2);
}

/* Enhance navigation buttons */
.nav-button-modal {
  transition: opacity 0.2s, transform 0.2s;
}

.nav-button-modal:hover {
  opacity: 0.9;
  transform: scale(1.1);
}

/* Replace the projects grid with slider styles */
.projects-slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 20px 0 40px;
}

.projects-slider {
  display: flex;
  transition: transform 0.3s ease-out;
  width: 100%;
  will-change: transform;
}

.slider-item {
  flex-shrink: 0;
  padding: 0 10px;
  box-sizing: border-box;
}

/* For mobile one item per view */
@media (max-width: 767px) {
  .slider-item {
    width: 100% !important;
  }
}

/* Slider navigation buttons */
.slider-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  pointer-events: none;
  z-index: 5;
}

.slider-nav-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-primary);
  color: var(--color-bg);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  pointer-events: auto;
}

.slider-nav-button:hover {
  background-color: var(--color-primary-variant);
  transform: scale(1.1);
}

.slider-nav-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.slider-nav-button.prev {
  margin-left: 10px;
}

.slider-nav-button.next {
  margin-right: 10px;
}

/* Slider pagination dots */
.slider-pagination {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
}

.slider-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  transition: all 0.2s ease;
}

.slider-dot.active {
  background-color: var(--color-primary);
  transform: scale(1.2);
}

.slider-dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

/* Animation for slider */
.projects-slider.sliding-next {
  animation: slideNextProject 0.3s forwards;
}

.projects-slider.sliding-prev {
  animation: slidePrevProject 0.3s forwards;
}

@keyframes slideNextProject {
  0% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slidePrevProject {
  0% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

/* Modal responsiveness improvements */
@media (max-width: 768px) {
  .modal-overlay {
    align-items: flex-start;
    padding-top: 30px;
    overflow-y: auto;
  }

  .modal-content {
    margin-top: 20px;
    max-height: none;
    overflow-y: visible;
  }

  .modal-body {
    padding-top: 25px;
    gap: 3rem;
  }

  .modal-phone-container {
    margin-top: 15px;
    padding-top: 25px;
    margin-bottom: 50px;
    position: relative;
  }

  .modal-phone {
    width: 190px;
    height: 400px;
  }

  .pagination-dots-modal {
    bottom: -40px;
    padding: 6px 12px;
  }
}

/* Additional fixes for very small screens */
@media (max-width: 480px) {
  .modal-overlay {
    padding-top: 20px;
  }

  .modal-content {
    width: 95%;
  }

  .modal-body {
    padding: 15px 10px;
  }

  .modal-phone-container {
    margin-top: 25px;
    margin-bottom: 60px;
  }

  .modal-phone {
    width: 170px;
    height: 360px;
  }

  .project-details-modal {
    max-height: 50vh;
    overflow-y: auto;
    padding: 0 5px;
  }

  .close-button {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1100;
    background: rgba(0, 0, 0, 0.7);
  }
}

/* Landscape mode adjustments */
@media (max-height: 600px) and (orientation: landscape) {
  .modal-body {
    grid-template-columns: 320px 1fr;
    gap: 1rem;
  }

  .modal-phone {
    width: 180px;
    height: 380px;
  }

  .modal-content {
    max-height: 96vh;
  }

  .project-details-modal {
    max-height: 70vh;
    overflow-y: auto;
  }

  .pagination-dots-modal {
    bottom: -20px;
  }
}

/* Improve slider responsiveness */
@media (max-width: 576px) {
  .slider-nav-button {
    width: 34px;
    height: 34px;
  }

  .slider-nav-button.prev {
    margin-left: 5px;
  }

  .slider-nav-button.next {
    margin-right: 5px;
  }

  .slider-pagination {
    margin-top: 15px;
  }

  .slider-dot {
    width: 8px;
    height: 8px;
  }
}

/* Fix touch target sizes for better mobile usability */
@media (max-width: 768px) {
  .toggle-button-modal {
    padding: 6px 14px;
    font-size: 13px;
  }

  .pagination-dot-modal {
    width: 10px;
    height: 10px;
    margin: 0 4px;
  }

  .toggle-buttons-modal {
    padding: 5px;
  }

  .project-content {
    padding: 1.25rem;
  }

  .project-footer {
    padding: 0 1.25rem 1.25rem;
  }
}

/* Styles for projects with only one screenshot (mockup) */
.project-mockup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}

.project-mockup-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.project-mockup-image:hover {
  transform: scale(1.03);
}

@media (max-width: 768px) {
  .project-mockup-container {
    max-width: 280px;
    margin-bottom: 20px;
  }
}